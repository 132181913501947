<template>
  <div class="work-detail-content" v-if="project"> 
    <div class="header scroll-hidden" v-scroll="'scroll-hidden'">
      <h1>{{ project.projectType.join(', ') }}</h1>
      <p>{{ project.projectDescription }}</p>
    </div>
    <div class="images">
      <img 
        v-for="(image, index) in project.detailImages" 
        :key="index" 
        :src="getImagePath(image.src)" 
        :alt="`Image ${index + 1}`" 
        class="scroll-hidden"
        v-scroll="'scroll-hidden'"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'WorkDetailContent',
  props: {
    project: {
      type: Object,
      required: true
    }
  },
  methods: {
    getImagePath(image) {
      try {
        return require(`@/assets/img_page_work_detail/${image}`);
      } catch (e) {
        console.error(`Image not found: ${image}`);
        return '';
      }
    }
  }
}
</script>

<style scoped>
.work-detail-content {
  display: flex;
  flex-direction: column;
}
.header {
  margin-bottom: 20px;
  color: black;
  text-align: center;
}
.images img {
  max-width: 100%;
  margin-bottom: 10px;
}
.details {
  margin-top: 20px;
  color: black;
}
.images {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
</style>
