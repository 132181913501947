<template>
  <button
    v-if="!href"
    class="button"
    :aria-label="msg"
  >
    <span class="textButton">{{ msg }}</span>
  </button>
  <a
    v-else
    :href="href"
    class="button"
    :aria-label="msg"
  >
    <span class="textButton">{{ msg }}</span>
  </a>
</template>

<script>

export default {
  name: 'ButtonComponent',
  props: {
    msg: String,
    href: String,
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.button {
  border: 1px solid #5294ff1e;
  background-color: #5294ff10;
  border-radius: 30px;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 13px;
  padding-bottom: 13px;
  box-shadow: inset 0px 0px 14px 0px rgba(82, 148, 255, 0.049);
  backdrop-filter: blur(10px);
}
.button:hover {
  background-color: #5294ff20;
  box-shadow: inset 0px 0px 20px 0px rgba(82, 148, 255, 0.1);
  transform: scale(1.05);
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}
.textButton {
  background: linear-gradient(90deg, rgb(255, 255, 255) 0%, rgb(163, 240, 248) 70%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
</style>
