<template>
  <div class="container" v-if="!reverse">
    <div class="left">
      <img class="img" :src="getImg(logo)" :alt="logo.replace('.png', '')" @contextmenu.prevent @mousedown.prevent />
    </div>
    <div class="right">
      <h2 class="gradient-text">{{ title }}</h2>
      <p>{{text}}</p>
      <ButtonComponent msg="Go for it !" href="mailto:hello@webeasy.pro" />
    </div>
  </div>
  <div class="container2" v-else>
    <div class="right">
      <h2 class="gradient-text">{{ title }}</h2>
      <p>{{text}}</p>
      <ButtonComponent msg="Go for it !" href="mailto:hello@webeasy.pro" />
    </div>
    <div class="left">
      <img class="img" :src="getImg(logo)" :alt="logo.replace('.png', '')" @contextmenu.prevent @mousedown.prevent />
    </div>
  </div>
</template>

<script>
import ButtonComponent from './ButtonComponent.vue';

export default {
  name: 'ServiceContainer',
  components: {
        ButtonComponent
    },
  props: {
    title: String,
    text: String,
    msg: String,
    reverse: Boolean,
    logo: String
  },
  methods: {
    getImg(url) {
      return require('../assets/'+url);
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.container {
  margin-top: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap:5%;
}
.container2 {
  margin-top: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap:5%;
}
.left {
/*   position: relative; */
  border-radius: 29px;
  border: 1px solid #5294ff35 ;
  padding: 3%;
  width: 45%;
  height: fit-content;
/*   height: 700px; */
  background-image: url('@/assets/bg1.webp');
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  width: 50%;
}
.img {
  width: 100%;
  animation: floating 3s ease-in-out infinite;
}
@keyframes floating {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0px);
  }
}


@media only screen and (max-width: 700px) {
  .container {
    flex-direction: column;
    align-items: center;
  }
  .container2 {
    flex-direction: column-reverse;
    align-items: center;
  }
  .left, .right {
    width: 70%;
/*     height: fit-content; */
  }
/*   .left {
    margin-bottom: 20px;
  } */
}
</style>
